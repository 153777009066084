import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import ThankYou from "../../../components/ConversionPath/demo-requested";
import Articles from "../../../components/Articles/Articles";
// import Image from '../../components/image';
import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
import { restaurantTestimonials } from "../../../data/restaurant-data";

const ArticlesHeader = {
  title: "Good reads",
  bgText: "",
};

export default function ThankYouPafe() {
  return (
    <Layout isLanding>
      <SEO title="Thank you for reaching out| SpotOn " />
      <ThankYou
        phoneNumber=""
        phoneText=""
        thankYouMessage="Thank you for your feedback. We've updated your preferences and will check back in a few months."
        thankYouSubheader="If you change your mind, you can always login to your SpotOn Dashboard to view your offer."
        ctaPrimary
      />
      <Articles
        sectionData={ArticlesHeader}
        blogFilters={{
          limit: 6,
          include: "tags,authors",
          filter: "tag:payments",
        }}
        cornerStone={false}
        style={{ marginTop: 60, marginBottom: 60 }}
      />
      <TestmonialReviews sectionData={restaurantTestimonials} />
    </Layout>
  );
}
